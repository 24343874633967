<template>
	<div class="library">
		<Hero title="Library" class-name="library" />
		<main>
			<div class="container">
				<div class="row">
					<div class="col-12">
						<blockquote>

							<p>True wisdom comes from prayer, reading Scripture, and quiet reflection. It means being silent, and listening for God’s word to us.</p>

							<footer><strong>Rabbi Yechiel Eckstein</strong></footer>

						</blockquote>

						<p>As a rabbi, Yechiel Eckstein was a learned and respected teacher, leading Jews and Christians alike to greater understanding of
							God’s Word. His upbringing, perhaps, started him down the path to this profession, as Yechiel’s father, Rabbi Sy Eckstein served as
							the rabbi of Ottawa, the capital of Canada. But Yechiel did not just ride on his father’s coattails, but studied and worked to make
							his own way as a man of God. He was ordained an Orthodox Rabbi by Yeshiva University, and earned advanced degrees there and from
							Columbia University. Besides his work building bridges between people of Jewish and Christian faiths, he also served on the
							faculties of Columbia, Chicago Theological Seminary, and Northern Baptist Seminary.</p>
						<p>But Yechiel was more than just a teacher. He was a lifelong learner, always seeking wisdom through the study of and reflection on
							God’s Word. May we all find in his library an example of how to read the Bible and find common ground between the faiths upon which
							His Word are founded.</p>

						<div class="row">
							<div class="col-12">
								<h3>
									The One Year® Holy Land Moments Devotional
								</h3>
								<a href="https://www.amazon.com/Year-Holy-Land-Moments-Devotional/dp/1414370210">
									<img class="img-thumbnail float-sm-left hlm-book" src="/static/images/Holy-Land-Memoments-Devotional.jpg" alt="The One Year® Holy Land Moments Devotional" title="The One Year® Holy Land Moments Devotional" />
								</a>
								<p>This year, come to know the God of the Bible better through the shared insights of Rabbi Yechiel Eckstein and Christian
									theologian Dr. Tremper Longman III. In the One Year® Holy Land Moments Devotional, each day offers a fascinating glimpse
									into the Jewish faith, history, and perspective, while exploring the Christian interpretation of beloved biblical
									verses, places, people and events.</p>

								<p>This devotional will provide you with an opportunity to grow closer in your relationship with the God of Israel as you
									gain a greater understanding and a new perspective on the Christian faith's deep roots in the land, events, people, and
									faith of the Holy Land. Spend a reflective moment each day contemplating the history of God's work in the world,
									celebrating His Word and love for you.
									<a href="https://www.amazon.com/Year-Holy-Land-Moments-Devotional/dp/1414370210">Learn More…</a>
								</p>
							</div>
						</div>

						<div class="mt-3">
							<h3>Books</h3>
							<div class="row publication">
								<div class="col-md-4">
									<a href="https://www.amazon.com/Holy-Songs-Israel-Inspirational-Worship/dp/0983532729/"><img class="img-fluid" src="/static/images/Holy-Songs-of-Israel.jpg" alt="Cover image for Holy Songs of Israel book" /></a>
									<h3>Holy Songs of Israel</h3>
									<p>
										This book celebrates the Psalms’ rich tradition through beautiful four-color imagery, teachings, and devotional
										reflections.
										<a href="https://www.amazon.com/Holy-Songs-Israel-Inspirational-Worship/dp/0983532729/">Learn More…</a>
									</p>
								</div>

								<div class="col-md-4">
									<a href="https://store.ifcj.org/collections/books/products/inspirational-gift-book-passover-your-inspirational-guide"><img class="img-fluid" src="/static/images/Passover-Guide.jpg" alt="Cover image for Passover: Your Inspirational Guide book" /></a>
									<h3>Passover: Your Inspirational Guide</h3>
									<p>The late Rabbi Eckstein discusses the significance of the Exodus to the Jewish faith, describes the rich traditions in
										the
										<em>seder</em>, and guides us through a traditional <em>seder</em> meal.
										<a href="https://store.ifcj.org/collections/books/products/inspirational-gift-book-passover-your-inspirational-guide">Learn More…</a>
									</p>
								</div>
							</div>
						</div>

						<h3>Music</h3>
						<div class="row music">
							<div class="col-md-4">
								<a href="https://www.amazon.com/Shiray-Shalom-Songs-Yechiel-Eckstein/dp/B001CBSA6C/">
									<img
										src="/static/images/Shiray-Shalom.jpg"
										alt="Cover image for Shiray Shalom, Songs of Peace album"
										class="img-fluid"
									/>
								</a>
								<h3>Shiray Shalom – Songs of Peace</h3>
								<p>
									This music expresses confidence that peace and joy will come to God's people despite adversity and that this peace is a
									gift from God.
									<a href="https://www.amazon.com/Shiray-Shalom-Songs-Yechiel-Eckstein/dp/B001CBSA6C/">Learn More…</a>
								</p>
							</div>

							<div class="col-md-4">
								<a href="https://www.amazon.com/SHIRAY-SIMCHA-SONGS-YECHIEL-ECKSTEIN/dp/B00EQNGFTC/">
									<img
										src="/static/images/Shiray-Simcha.jpg"
										alt="Cover image for Shiray Simcha, Songs of Joy album"
										class="img-fluid"
									/>
								</a>
								<h3>Shiray Simcha – Songs of Joy</h3>
								<p>
									These upbeat and lively Hebrew songs celebrate the joy of life and faith through words of hope and strength drawn from
									the Scriptures.
									<a href="https://www.amazon.com/SHIRAY-SIMCHA-SONGS-YECHIEL-ECKSTEIN/dp/B00EQNGFTC/">Learn More…</a>
								</p>
							</div>

							<div class="col-md-4">
								<a href="https://www.amazon.com/Shiray-Tikvah-Songs-Yechiel-Eckstein/dp/B01GZ19W8I/">
									<img src="/static/images/Shiray-Tikvah.jpg" alt="Cover image for Shiray Tikvah, Songs of Hope album" class="img-fluid" />
								</a>
								<h3>Shiray Tikvah, Songs of Hope</h3>
								<p>In these songs, recorded exclusively for our <em>Fellowship</em> Collection, Rabbi Eckstein blended his musical talents
									with heartfelt spiritual devotion to create a listening and worship experience unlike any other.
									<a href="https://www.amazon.com/Shiray-Tikvah-Songs-Yechiel-Eckstein/dp/B01GZ19W8I/">Learn More…</a>
								</p>
							</div>
						</div>
						<h3>Bible Studies</h3>
						<div class="row videos">
							<div class="col-md-4">
								<a href="https://store.ifcj.org/collections/music/products/keys-to-shalom-dvd-bible-study">
									<img src="/static/images/Keys-to-Shalom.jpg" alt="Cover image for Keys to Shalom DVD Bible Study" class="img-fluid" />
								</a>
								<h3>Keys to Shalom DVD Bible Study</h3>
								<p>Rabbi Eckstein shared the Jewish perspective of shalom, peace, and taught how we can build bridges of peace with others
									in this easy-to-use Bible study.
									<a href="https://store.ifcj.org/collections/music/products/keys-to-shalom-dvd-bible-study">Learn More…</a>
								</p>
							</div>

							<div class="col-md-4">
								<a href="https://store.ifcj.org/collections/music/products/the-biblical-temples-dvd-bible-study">
									<img src="/static/images/Biblical-Temples.jpg" alt="Cover image for The Biblical Temples DVD Bible Study" class="img-fluid" />
								</a>
								<h3>The Biblical Temples DVD Bible Study</h3>
								<p>In this five-part biblical study, Rabbi Eckstein helped Christians understand the significance of the Temple to Jewish
									life and worship, as well as the significance of the Third Temple.
									<a href="https://store.ifcj.org/collections/music/products/the-biblical-temples-dvd-bible-study">Learn More…</a>
								</p>
							</div>

							<div class="col-md-4">
								<a href="https://www.amazon.com/Jewish-Roots-Gods-Word-Journey/dp/B009KTUFQQ/">
									<img src="/static/images/Jewish-Roots.jpg" alt="Cover image for The Jewish Roots of God’s Word DVD Bible Study" class="img-fluid" />
								</a>
								<h3>The Jewish Roots of God’s Word DVD Bible Study</h3>
								<p>Rabbi Yechiel Eckstein shared with his Christian audience the Jewish perspective of God’s Word and what conclusions we
									share about the Bible as the bedrock of both Christianity and Judaism.
									<a href="https://www.amazon.com/Jewish-Roots-Gods-Word-Journey/dp/B009KTUFQQ/">Learn More…</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Hero from '@/components/Hero.vue'
export default {
	name: 'Library',
	components: {
		Hero
	}
}
</script>

<style lang="scss" scoped>
img {
	width: 100%;
	margin-bottom: 1rem;
}

@media only screen and (min-width: 576px) {
	.hlm-book {
		max-width: 300px; 
		margin: 0 1rem 1rem 0;
	}
}

</style>
