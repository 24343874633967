<template>
	<ul class="cards">
		<li v-for="(card, index) in collection" v-bind:key="index" class="card">
			<router-link :to="card.linkUrl">
				<div class="card__image">
					<img v-bind:src="card.imageUrl" />
				</div>
				<div class="card__content">
					<div class="card__title">
						<h3 v-html="card.title"></h3>
						<!-- <h3>{{ card.title }}</h3> -->
					</div>
					<p class="card__text">
						<span v-html="card.content"></span>
						<!-- {{ card.content }} -->
					</p>
				</div>
			</router-link>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'Cards',
	props: {
		collection: {
			type: Array,
			required: true
		}
	}
}
</script>

<style lang="scss" scoped>
.cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 0;
	margin: 0;

	.card {
		background-color: #fff;
		box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.05);
		display: flex;
		flex-direction: column;
		min-height: 30rem;
		height: auto;
		margin: 0 1rem 1rem 0;
		transition: all 0.15s ease-in-out;

		&:hover {
			box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
		}

		a {
			color: #000;
			text-decoration: none;
			display: block;
			height: 100%;

			&:hover {
				cursor: pointer;
			}
		}

		&__content {
			padding: 1rem;
			overflow: hidden;
		}

		&__image {
			border-bottom: 10px solid #5c3900;

			img {
				object-fit: cover;
				width: 100%;
			}
		}

		&__title {
			color: rgba(#5c3900, 0.6);
			text-transform: uppercase;
		}

		&__text {
			line-height: 1.75rem;
		}
	}
}

@media (min-width: 768px) {
	.card {
		width: 250px;
	}
}
</style>
