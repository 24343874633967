<template>
	<div>
		<Hero title="memorial coverage" class-name="memorial-coverage" />
		<main>
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-12">
						<p>
							In keeping with Jewish custom, Rabbi Eckstein’s funeral was carried out within 24 hours of his death. His body was buried at Eretz
							HaChaim Cemetery, which is near the city of Beit Shemesh, not far from Jerusalem . The ceremony concluded with a prayer to affirm
							the Jewish belief that the rabbi’s soul is now with God, and that God is great.
						</p>
						<p>
							Many noteworthy individuals attended the funeral, including U.S. Ambassador to Israel David Friedman, Israeli
							<em>Knesset</em> members, former Jerusalem Mayor Nir Barkat, and Rabbi Berel Lazar, head of Chabad in Russia. Messages of condolence
							came in from many more prominent leaders around the world, who paid tribute to Rabbi Eckstein for his trailblazing work building
							bridges between Christians and Jews. For all those who could not attend, we share these special moments from Rabbi Eckstein’s
							funeral with you so that you may pay your respects.
						</p>

						<h3>Ceremony</h3>

						<hr />

						<div class="row">
							<div class="col-md-4 col-xs-12">
								<a v-on:click="open()" href="#img1">
									<img src="/static/images/RYE-Funeral-14625835.jpg" class="img-thumbnail" />
								</a>
								<a href="#_" class="lightbox" id="img1">
									<a href="#" class="close" v-on:click="hide()">&times;</a>
									<img src="/static/images/RYE-Funeral-14625835.jpg" />
								</a>
							</div>

							<div class="col-md-4 col-xs-12">
								<a v-on:click="open()" href="#img2">
									<img src="/static/images/RYE-Funeral-14625847.jpg" class="img-thumbnail" />
								</a>
								<a href="#_" class="lightbox" id="img2">
									<a href="#" class="close" v-on:click="hide()">&times;</a>
									<img src="/static/images/RYE-Funeral-14625847.jpg" />
								</a>
							</div>

							<div class="col-md-4 col-xs-12">
								<a v-on:click="open()" href="#img3">
									<img src="/static/images/RYE-Funeral-14625901.jpg" class="img-thumbnail" />
								</a>
								<a href="#_" class="lightbox" id="img3">
									<a href="#" class="close" v-on:click="hide()">&times;</a>
									<img src="/static/images/RYE-Funeral-14625901.jpg" />
								</a>
							</div>
						</div>

						<div class="row">
							<div class="col-md-4 col-xs-12">
								<a v-on:click="open()" href="#img4">
									<img src="/static/images/RYE-Funeral-14626033.jpg" class="img-thumbnail" />
								</a>
								<a href="#_" class="lightbox" id="img4">
									<a href="#" class="close" v-on:click="hide()">&times;</a>
									<img src="/static/images/RYE-Funeral-14626033.jpg" />
								</a>
							</div>

							<div class="col-md-4 col-xs-12">
								<a v-on:click="open()" href="#img5">
									<img src="/static/images/RYE-Funeral-14626051.jpg" class="img-thumbnail" />
								</a>
								<a href="#_" class="lightbox" id="img5">
									<a href="#" class="close" v-on:click="hide()">&times;</a>
									<img src="/static/images/RYE-Funeral-14626051.jpg" />
								</a>
							</div>

							<div class="col-md-4 col-xs-12">
								<a v-on:click="open()" href="#img6">
									<img src="/static/images/RYE-Funeral-14626079.jpg" class="img-thumbnail" />
								</a>
								<a href="#_" class="lightbox" id="img6">
									<a href="#" class="close" v-on:click="hide()">&times;</a>
									<img src="/static/images/RYE-Funeral-14626079.jpg" />
								</a>
							</div>
						</div>

						<div class="row">
							<div class="col-md-4 col-xs-12">
								<a v-on:click="open()" href="#img7">
									<img src="/static/images/RYE-Funeral-14626081.jpg" class="img-thumbnail" />
								</a>
								<a href="#_" class="lightbox" id="img7">
									<a href="#" class="close" v-on:click="hide()">&times;</a>
									<img src="/static/images/RYE-Funeral-14626081.jpg" />
								</a>
							</div>

							<div class="col-md-4 col-xs-12">
								<a v-on:click="open()" href="#img8">
									<img src="/static/images/RYE-Funeral-14625947.jpg" class="img-thumbnail" />
								</a>
								<a href="#_" class="lightbox" id="img8">
									<a href="#" class="close" v-on:click="hide()">&times;</a>
									<img src="/static/images/RYE-Funeral-14625947.jpg" />
								</a>
							</div>

							<div class="col-md-4 col-xs-12">
								<a v-on:click="open()" href="#img9">
									<img src="/static/images/RYE-Funeral-14625887.jpg" class="img-thumbnail" />
								</a>
								<a href="#_" class="lightbox" id="img9">
									<a href="#" class="close" v-on:click="hide()">&times;</a>
									<img src="/static/images/RYE-Funeral-14625887.jpg" />
								</a>
							</div>
						</div>

						<div class="row">
							<div class="col-md-4 col-xs-12">
								<a v-on:click="open()" href="#img10">
									<img src="/static/images/RYE-Funeral-14625875.jpg" class="img-thumbnail" />
								</a>
								<a href="#_" class="lightbox" id="img10">
									<a href="#" class="close" v-on:click="hide()">&times;</a>
									<img src="/static/images/RYE-Funeral-14625875.jpg" />
								</a>
							</div>

							<div class="col-md-4 col-xs-12">
								<a v-on:click="open()" href="#img11">
									<img src="/static/images/RYE-Funeral-14625863.jpg" class="img-thumbnail" />
								</a>
								<a href="#_" class="lightbox" id="img11">
									<a href="#" class="close" v-on:click="hide()">&times;</a>
									<img src="/static/images/RYE-Funeral-14625863.jpg" />
								</a>
							</div>

							<div class="col-md-4 col-xs-12">
								<a v-on:click="open()" href="#img12">
									<img src="/static/images/RYE-Funeral-14626121.jpg" class="img-thumbnail" />
								</a>
								<a href="#_" class="lightbox" id="img12">
									<a href="#" class="close" v-on:click="hide()">&times;</a>
									<img src="/static/images/RYE-Funeral-14626121.jpg" />
								</a>
							</div>
						</div>

						<h3>Burial</h3>

						<hr />

						<div class="row">
							<div class="col-md-4 col-xs-12">
								<a v-on:click="open()" href="#img13">
									<img src="/static/images/RYE-Funeral-14626167.jpg" class="img-thumbnail" />
								</a>
								<a href="#_" class="lightbox" id="img13">
									<a href="#" class="close" v-on:click="hide()">&times;</a>
									<img src="/static/images/RYE-Funeral-14626167.jpg" />
								</a>
							</div>

							<div class="col-md-4 col-xs-12">
								<a v-on:click="open()" href="#img14">
									<img src="/static/images/RYE-Funeral-14625703.jpg" class="img-thumbnail" />
								</a>
								<a href="#_" class="lightbox" id="img14">
									<a href="#" class="close" v-on:click="hide()">&times;</a>
									<img src="/static/images/RYE-Funeral-14625703.jpg" />
								</a>
							</div>

							<div class="col-md-4 col-xs-12">
								<a v-on:click="open()" href="#img15">
									<img src="/static/images/RYE-Funeral-14625695.jpg" class="img-thumbnail" />
								</a>
								<a href="#_" class="lightbox" id="img15">
									<a href="#" class="close" v-on:click="hide()">&times;</a>
									<img src="/static/images/RYE-Funeral-14625695.jpg" />
								</a>
							</div>
						</div>

						<div class="row">
							<div class="col-md-4 col-xs-12">
								<a v-on:click="open()" href="#img16">
									<img src="/static/images/RYE-Funeral-14625685.jpg" class="img-thumbnail" />
								</a>
								<a href="#_" class="lightbox" id="img16">
									<a href="#" class="close" v-on:click="hide()">&times;</a>
									<img src="/static/images/RYE-Funeral-14625685.jpg" />
								</a>
							</div>

							<div class="col-md-4 col-xs-12">
								<a v-on:click="open()" href="#img17">
									<img src="/static/images/RYE-Funeral-14625987.jpg" class="img-thumbnail" />
								</a>
								<a href="#_" class="lightbox" id="img17">
									<a href="#" class="close" v-on:click="hide()">&times;</a>
									<img src="/static/images/RYE-Funeral-14625987.jpg" />
								</a>
							</div>

							<div class="col-md-4 col-xs-12"></div>
						</div>

						<h3>Videos</h3>

						<hr />

						<div class="row">
							<div class="col-md-6">
								<a v-on:click="open()" href="#player_1"><img src="/static/images/posters/6118310274001.jpg" class="img-thumbnail"/></a>
								<div id="player_1" class="lightbox">
									<a href="#" class="close" v-on:click="hide()">&times;</a>
									<iframe
										v-if="isActive"
										src="https://player.vimeo.com/video/777043178?h=b65c0348eb&badge=0&autopause=0&player_id=0&app_id=58479" title="Arutz Sheva TV - Parting from Rabbi Yechiel Eckstein"
										allow="encrypted-media"
									>
									</iframe>
								</div>
							</div>
							<div class="col-md-6">
								<a v-on:click="open()" href="#player_2"><img src="/static/images/posters/6000156436001.jpg" class="img-thumbnail"/></a>
								<div id="player_2" class="lightbox">
									<a href="#" class="close" v-on:click="hide()">&times;</a>
									<iframe
										v-if="isActive"
										src="https://player.vimeo.com/video/776668292?h=b65c0348eb&badge=0&autopause=0&player_id=0&app_id=58479" title="Rabbi Eckstein's Funeral Eulogies"
										allow="encrypted-media"
									>
									</iframe>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import videos from '../../assets/data/videos.json'

export default {
	name: 'MemorialCoverage',
	components: {
		Hero
	},
	data() {
		return {
			videos: videos,
			isActive: false
		}
	},
	methods: {
		open() {
			this.isActive = true
		},
		hide() {
			this.isActive = false
		}
	}
}
</script>

<style lang="scss" scoped>
.lightbox {
	visibility: hidden;
	opacity: 0;
	position: fixed;
	z-index: 1000;
	width: 100%;
	height: 100%;
	text-align: center;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.8);

	img {
		max-width: 90%;
		max-height: 80%;
		position: absolute;
		top: 10%;
		margin: auto;
		right: 0;
		left: 0;
	}

	&:target {
		visibility: visible;
		opacity: 1;
	}

	&:target iframe {
		opacity: 1;
		transition: opacity 0.5ms ease-in;
	}
}

.close {
	&:link,
	&:visited {
		cursor: pointer;
		color: #fff;
		position: absolute;
		top: 1rem;
		right: 2rem;
		font-size: 3rem;
		text-decoration: none;
		display: inline-block;
		transition: all 0.2s;
		line-height: 1;
		opacity: 0.5;
	}

	&:hover {
		opacity: 1;
	}
}

.img-thumbnail {
	margin-bottom: 1rem;
}

iframe {
	max-width: 90%;
	max-height: 80%;
	height: 100%;
	width: 100%;

	position: absolute;
	top: 10%;
	margin: auto;
	right: 0;
	left: 0;
	overflow: hidden;

	opacity: 0;
	transition: all 0.5s 0.2s;
}
</style>
