import Vue from 'vue'
import VueRouter from 'vue-router'

import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'

import { Routes } from './routes';
import MotivationCodes from './libraries/AnanNet/MotivationCodes';

import App from './App.vue';

import '@fortawesome/fontawesome-free/js/all.min.js'
import './sass/vendor.scss'
import './sass/main.scss'

Vue.use(VueRouter)

// Environment
Vue.config.productionTip = process.env.NODE_ENV !== 'production';

// Base API
window.baseApiUrl = process.env.VUE_APP_BASE_API_URL;

// reCAPTCHA
window.reCAPTCHA = {
	siteKey: '6LesaYUUAAAAABnO_sXEYyr3OkfP6VPGYOfl2lDA'
};

// Motivation Code
window.motivationCode = MotivationCodes.getCode();
MotivationCodes.setCode(window.motivationCode);

const newRelicOptions = {
	init: {
		distributed_tracing:{enabled:true},
		privacy:{cookies_enabled:true},
		ajax:{deny_list:["bam.nr-data.net"]}
	}, // NREUM.init
	info: { 
		beacon:"bam.nr-data.net",
		errorBeacon:"bam.nr-data.net",
		licenseKey:"NRJS-59dea2f9ad1cc30a8b3",
		applicationID: process.env.VUE_APP_NEW_RELIC_APPLICATION_ID,
		sa:1
	}, // NREUM.info
	loader_config: { 
		accountID:"4154585",
		trustKey:"4154585",
		agentID: process.env.VUE_APP_NEW_RELIC_APPLICATION_ID,
		licenseKey:"NRJS-59dea2f9ad1cc30a8b3",
		applicationID:process.env.VUE_APP_NEW_RELIC_APPLICATION_ID
	} // NREUM.loader_config
}

new BrowserAgent(newRelicOptions);

const metaTags = document.getElementsByTagName('meta');

const router = new VueRouter({
	mode: 'history',
	routes: Routes,
	scrollBehavior () {
		return {
			x: 0,
			y: 0
		}
	},
	base: process.env.BASE_URL,
	linkExactActiveClass: 'active'
})

router.beforeEach((to, from, next) => {

	for (let i = 0; i < metaTags.length; i++) {

		if (metaTags[i].getAttribute('name') === 'description') {

			metaTags[i].setAttribute('content', to.meta.description);
		}
	}

    if (to.meta.title === undefined) {

        document.title = process.env.VUE_APP_TITLE + ' | ' + process.env.VUE_APP_ORG;
    }
    else {

        document.title = to.meta.title + ' | ' + process.env.VUE_APP_ORG;
    }

	next();
});

new Vue({
	router,
	render: h => h(App),
}).$mount('#app');
