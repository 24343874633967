<template>
	<div class="ministry">

		<Hero title="Ministry" class-name="ministry" />

		<main>

			<div class="container">
				<div class="row justify-content-center">
					<div class="col-12">
						<p>
							How do you break down barriers and ease hostilities that have built up over 2,000 years? How do you build bridges between two groups
							whose histories have been marked more by distrust, hate, and fear than cooperation, love, and compassion? And how do you unite these
							people into a potent force to feed the hungry, shelter the homeless, and provide clothing and other essential assistance to those in
							need?
						</p>

						<h3>It Takes a Calling</h3>

						<p>
							A young Rabbi in Chicago encountered an evangelical pastor and was shocked to discover a love for Israel that mirrored his own.
							Rabbi Yechiel Eckstein later said of this moment, “I felt God calling me to do the impossible: to build bridges of cooperation and
							healing and understanding between Christians and Jews.”
						</p>
						<p>
							He had worked with Christians before in an effort to increase their understanding of the Jewish community and Judaism. However,
							Rabbi Eckstein never imagined that he would become the “cornerstone” (the meaning of “Eckstein”) in uniting God’s children, or that
							this holy work, as he said, “would be as successful as God has made it.”
						</p>

						<h3>It Takes Courage</h3>

						<p>
							When Reverend Bailey Smith of the Southern Baptist Convention said, “God Almighty does not hear the prayer of a Jew,” Rabbi Eckstein
							saw an opportunity where most in the Jewish community only saw offense. After inviting Rev. Smith to Israel, a friendship was born
							that would impact the relationship between Christians and Jews for decades to come.
						</p>
						<p>
							Rabbi Eckstein endured years of criticism from Jews and Christians alike for his willingness to walk in both worlds and defend his
							friends on both sides. He approached the role of mediator with a smile, and dedicated himself to building bridges.
						</p>

						<h3>It Takes Commitment</h3>

						<p>
							Rabbi Eckstein remained ever focused on his calling, tirelessly working to build a ministry that would help as many people as
							possible. And as his ministry grew, so did his compassion. Everywhere he went, he found Jews searching for love and understanding,
							and Christians who were longing to extend it. He spent his life bringing them together in fellowship.
						</p>
						<p>
							Rabbi Eckstein built bridges that challenged history, defied convention, and ultimately brought healing to the world. Today, the
							ministry he founded blesses millions of Christians and Jews around the world, and is the largest channel of Christian support for
							Israel.
						</p>
						<p>
							Through Rabbi Eckstein’s ministry, Christians are partaking in the blessing found in the Bible that says God will bless those who
							bless His people (Genesis 12:3). They are fulfilling prophecy by helping Jews return to the promised land of Israel (Isaiah 49:22).
							They are observing God’s true fast by feeding hungry Holocaust survivors (Isaiah 58:6-7). They are praying for the peace of
							Jerusalem by providing security to those threatened in Israel (Psalm 122:6).
						</p>
						<p>
							None of this would have happened were it not for the calling, courage, and commitment of one man. Rabbi Eckstein’s life was a
							testament to a simple fact: once someone has been touched by God, amazing things can happen.
						</p>
						<h3>IFCJ Mission Statement</h3>
						<p>
						The <em>International Fellowship of Christian and Jews</em> is the leading non-profit building bridges between Christians and Jews, blessing Israel and the Jewish people around the world with humanitarian care and life-saving aid.
						</p>
					</div>
				</div>
			</div>

		</main>

	</div>
</template>

<script>
import Hero from '@/components/Hero.vue'

export default {
	name: 'Ministry',
	components: {
		Hero
	}
}
</script>

<style lang="scss" scoped></style>
