import Home from './views/pages/Home.vue'
import Legacy from './views/pages/Legacy.vue'
import LegacyBiography from './views/pages/LegacyBiography.vue'
import LegacyMinistry from './views/pages/LegacyMinistry.vue'
import LegacyQuotes from './views/pages/LegacyQuotes.vue'
import Gallery from './views/pages/Gallery.vue'
import MemorialCoverage from './views/pages/MemorialCoverage.vue'
import Memories from './views/pages/Memories.vue'
import MemoriesCondolences from './views/pages/MemoriesCondolences.vue'
import MemoriesShare from './views/pages/MemoriesShare.vue'
import MemoriesReflection from './views/pages/MemoriesReflection.vue'
import Library from './views/pages/Library.vue'
import More from './views/pages/More.vue'
import Contact from './views/pages/Contact.vue'
import BridgeBuilder from './views/pages/BridgeBuilder.vue'

const Routes = [
	{
		path: '/',
		component: Home,
		meta: {
			title: 'Home',
			description: 'Remembering the life and accomplishments of Rabbi Yechiel Eckstein (1951 – 2019)'
		}
	},
	{
		path: '/legacy',
		component: Legacy,
		meta: {
			title: 'Legacy',
			description: 'Rabbi Yechiel Eckstein founded the International Fellowship of Christians and Jews (The Fellowship) in 1983 and devoted his life to building bridges of understanding and cooperation between Christians and Jews and broad support for the state of Israel. But he was much, much more.'
		}
	},
	{
		path: '/legacy/biography',
		component: LegacyBiography,
		meta: {
			title: 'Biography',
			description: 'Growing up in an orthodox Jewish household, Yechiel Eckstein wrestled his way through adversity to create one of the world’s largest philanthropic organizations for Jews.'
		}
	},
	{
		path: '/legacy/ministry',
		component: LegacyMinistry,
		meta: {
			title: 'Ministry',
			description: 'Rabbi Eckstein worked with Christians in an effort to increase their understanding of the Jewish community and Judaism. He walked in both world, endured years of criticism and worked tirelessly to build a ministry that would serve God’s people in many ways.'
		}
	},
	{
		path: '/legacy/quotes',
		component: LegacyQuotes,
		meta: {
			title: 'Quotes',
			description: '“They see me as someone through whom they can bond with the people, the land, and the God of Israel and discover the Jewish roots of their Christianity”, Rabbi Eckstein'
		}
	},
	{
		path: '/gallery',
		component: Gallery,
		meta: {
			title: 'Media Gallery',
			description: 'Photo and video gallery of the respected teacher and speaker – Rabbi Yechiel Eckstein.'
		}
	},
	{
		path: '/memorial-coverage',
		component: MemorialCoverage,
		meta: {
			title: 'Memorial Coverage',
			description: 'Funeral services and proceedings were modest and according to Jewish custom.'
		}
	},
	{
		path: '/memories',
		component: Memories,
		meta: {
			title: 'Memories',
			description: 'After Rabbi Yechiel Eckstein’s passing, memories and tributes poured in from around the globe from prominent leaders, friends, and family whose lives were touched by him. We now share their words with you.'
		}
	},
	{
		path: '/memories/memories-condolences',
		component: MemoriesCondolences,
		meta: {
			title: 'Memories and Condolences',
			description: 'Pat Boone on Yechiel Eckstein, “I will always consider my friendship and cooperative work with Yechiel Eckstein one of the most important facts of my life on this earth”'
		}
	},
	{
		path: '/memories/share-your-memory',
		component: MemoriesShare,
		meta: {
			title: 'Share Your Memory',
			description: 'How did Rabbi Eckstein influence your life?'
		}
	},
	{
		path: '/memories/yael-eckstein-reflection',
		component: MemoriesReflection,
		meta: {
			title: "Yael Eckstein's Reflection",
			description: 'Yael Eckstein describes the influence of her father on her life as a daughter and as the next generation leader of IFCJ.'
		}
	},
	{
		path: '/library',
		component: Library,
		meta: {
			title: 'Library',
			description: 'Rabbi Eckstein was a lifelong learner, always seeing wisdom through the study of God’s Word.'
		}
	},
	{
		path: '/more',
		component: More,
		meta: {
			title: 'More',
			description: 'Learn How to be a Bridge Builder and Contact The Fellowship as you continue to explore the life of Rabbi Yechiel Eckstein.'
		}
	},
	{
		path: '/more/contact',
		component: Contact,
		meta: {
			title: 'Contact',
			description: 'Contact IFCJ regarding the life and ministry of Rabbi Yechiel Eckstein.'
		}
	},
	{
		path: '/more/bridge-builder',
		component: BridgeBuilder,
		meta: {
			title: 'How To Be A Bridge Builder',
			description: 'The bridge between Christians and Jews took decades, but the Rabbi’s life’s work now spans the world and leads to Jerusalem'
		}
	}
]

export { Routes }
