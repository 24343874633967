<template>
	<div>
		<Hero title="share your memory" class-name="share-your-memory" />
		<main>
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-12">
						<form v-if="isSuccess === false" v-on:submit.prevent="postSurvey" v-bind:class="{'was-validated': isSubmitted}">
							<p>
								Rabbi Yechiel Eckstein’s life and ministry influenced millions of people across the globe to come together in fellowship and grow
								closer to God. We’d love to hear how Rabbi Eckstein influenced your life and what he meant to you! Please share with us your
								personal testimony on the impact he made for you.
							</p>
							<div class="row mb-3">
								<div class="form-group col-md-6">
									<label for="firstName" class="form-label">First Name</label>
									<input id="firstName" v-model="form.contact.firstName" autocomplete="given-name" type="text" class="form-control" required />
								</div>
								<div class="form-group col-md-6">
									<label for="lastName" class="form-label">Last Name</label>
									<input id="lastName" v-model="form.contact.lastName" autocomplete="family-name" type="text" class="form-control" required />
								</div>
							</div>
							<div class="row mb-3">
								<div class="form-group col-md-6">
									<label for="emailAddress" class="form-label">Email</label>
									<input id="emailAddress" v-model="form.contact.emailAddress" autocomplete="email" type="email" class="form-control" required />
								</div>
								<div class="form-group col-md-6">
									<label for="phoneNumber" class="form-label">Phone Number</label>
									<input id="phoneNumber" v-model="form.contact.phoneNumber" autocomplete="tel" type="tel" class="form-control" required />
								</div>
							</div>

							<div class="row mb-3">
								<div class="form-group col-md-12">
									<label for="memory" class="form-label">Memory</label>
									<textarea rows="6" id="note" v-model="form.note" class="form-control" required></textarea>
								</div>
							</div>
							<div class="submit">
								<div class="row d-flex justify-content-center">
									<div class="col-xs-12 col-sm-4 d-grid">
										<button type="submit" class="btn btn-outline-primary" v-bind:disabled="isProcessing">
											Submit Your Memory
											<span v-show="isProcessing"><i class="fas fa-spinner fa-pulse ml-2"></i></span>
										</button>
									</div>
								</div>
							</div>
						</form>
						<div v-if="isSuccess" class="alert alert-success text-center">
							Thank you for submitting your testimony and sharing your memory of Rabbi Eckstein with us. May his memory be a blessing, and his life’s work continue to educate and strengthen you in your faith.
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Axios from 'axios';
import Hero from '@/components/Hero.vue';

export default {
	name: 'MemoriesShare',
	components: {
		Hero
	},
	data() {
		return {
			primaryId: 107,
			secondaryId: 0,
			motivationCode: window.motivationCode,
			emailId: this.$route.query.emid === undefined ? '' : this.$route.query.emid,
			form: {
				contact: {
					firstName: '',
					lastName: '',
					emailAddress: '',
					phoneNumber: '',
					streetAddress: '',
					city: '',
					state: '',
					country: '',
					postalCode: ''
				},
				note: ''
			},
			isSubmitted: false,
			isProcessing: false,
			isSuccess: false
		}
	},
	methods: {

		postSurvey() {

			this.isSubmitted = true;
			this.isProcessing = true;

			window.grecaptcha.ready(() => {
				window.grecaptcha.execute(window.reCAPTCHA.siteKey, {action: 'RabbiEcksteinMemory'})
					.then((token) => {

						let config = {
							headers: {
								'reCAPTCHA-Token': token
							}
						};

						let payload = {
							formId: 0,
							primaryId: this.primaryId,
							secondaryId: this.secondaryId,
							emailId: this.emailId,
							motivationCode: this.motivationCode,
							contact: this.form.contact,
							note: this.form.note,
							subscriptions: []
						};

						Axios.post(window.baseApiUrl + '/forms/survey', payload, config)
							.then((response) => {

								this.isProcessing = false;
								this.isSuccess = true;

								return response;
							})
							.catch((error) => {

								this.isProcessing = false;

								return error;
							});

					});
			});
		}
	}
}
</script>

<style lang="scss" scoped>
</style>
