<template>
	<div>
		<Hero title="Media Gallery" class-name="media-gallery" />
		<main>
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-12">
						<p>
							Throughout his life, Rabbi Eckstein was known and respected as a teacher and speaker who loved to share his extensive knowledge of
							the Bible, Judaism, and Jewish-Christian relations. As you browse these videos, we hope you will recall the rich legacy of learning that he left to the world.
						</p>

						<hr />

						<div class="row">
							<div class="col-md-4 mb-3" v-for="(video, index) in videos" v-bind:key="index">
								<a v-on:click="open()" v-bind:href="'#player_' + [[index + 1]]">
									<img v-bind:src="[video.poster]" class="img-thumbnail" />
								</a>
								<div class="lightbox" v-bind:id="[video.id]">
									<a href="#_" class="close" v-on:click="hide()">&times;</a>
									<iframe v-if="isActive" v-bind:id="[video.id]" v-bind:src="'https://player.vimeo.com/video/' + [video.videoId]" v-bind:title="[video.title]" allow="encrypted-media">
									</iframe>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import videos from '@/assets/data/videos.json'

export default {
	name: 'Gallery',
	components: {
		Hero
	},
	data() {
		return {
			videos: videos,
			isActive: false
		}
	},
	methods: {
		open() {
			this.isActive = true
		},
		hide() {
			this.isActive = false
		}
	}
}
</script>

<style lang="scss" scoped>
.lightbox {
	visibility: hidden;
	opacity: 0;
	position: fixed;
	z-index: 1000;
	width: 100%;
	height: 100%;
	text-align: center;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.8);

	&:target {
		visibility: visible;
		opacity: 1;
	}

	&:target iframe {
		opacity: 1;
		transition: opacity 0.5ms ease-in;
	}

	.close {
		&:link,
		&:visited {
			cursor: pointer;
			color: #fff;
			position: absolute;
			top: 1rem;
			right: 2rem;
			font-size: 3rem;
			text-decoration: none;
			display: inline-block;
			transition: all 0.2s;
			line-height: 1;
			opacity: 0.5;
		}

		&:hover {
			opacity: 1;
		}
	}
}

iframe {
	max-width: 90%;
	max-height: 80%;
	height: 100%;
	width: 100%;

	position: absolute;
	top: 10%;
	margin: auto;
	right: 0;
	left: 0;
	overflow: hidden;

	opacity: 0;
	transition: all 0.5s 0.2s;
}
</style>
