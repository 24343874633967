<template>
	<div class="legacy">
		<Hero title="Legacy" class-name="legacy" />
		<main>
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-12">
						<Cards v-bind:collection="cards" />
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import Cards from '@/components/Cards.vue'

export default {
	name: 'Legacy',
	components: {
		Hero,
		Cards
	},
	data() {
		return {
			cards: [
				{
					title: 'Biography',
					content:
						'Growing up in an orthodox Jewish household, Yechiel Eckstein wrestled his way through adversity to create one of the world’s largest philanthropic organizations for Jews.',
					imageUrl: '/static/images/Bio-14625641.jpg',
					linkUrl: '/legacy/biography'
				},
				{
					title: 'Ministry',
					content:
						'Rabbi Eckstein worked with Christians to increase their understanding of the Jewish people and built a ministry that would serve God’s people in many ways.',
					imageUrl: '/static/images/Ministry-14483438.jpg',
					linkUrl: '/legacy/ministry'
				},
				{
					title: 'Quotes',
					content:
						'"They see me as someone through whom they can bond with the people, the land, and the God of Israel and discover the Jewish roots of their Christianity”, Rabbi Eckstein.',
					imageUrl: '/static/images/Biography-14310091.jpg',
					linkUrl: '/legacy/quotes'
				}
			]
		}
	}
}
</script>

<style lang="scss" scoped></style>
