<template>
	<div>
		<Hero title="Yael Eckstein’s Reflection" class-name="reflection" />
		<main>
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-12">

						<h3>The Most Important Title Is Abba</h3>

						<p>
							As far back as I can remember my father began every day earlier than the rest of our household. Day in and day out, my
							<em>abba</em>, Rabbi Yechiel Eckstein, would wake up at 5:00 in the morning so that he would have time to pray and study the Bible
							before heading out for the day. He arrived at his office at 7:00 each morning ready to put in another long day following his vision
							of building bridges between the Jewish and Christian communities.
						</p>

						<p>
							I didn’t have a clue about what my father did every day when I was little, and I had no idea that his work would change the world.
							All I knew was that he worked very hard each day and came home just before my older sisters and I went to bed. Tired from another
							long day, my father did not crash on the sofa or head straight to the kitchen. He made it a priority to put us to bed.
						</p>

						<p>
							I can still hear him reciting the <em>shema</em> prayer with us at night, singing the traditional Hebrew lullabies, and rubbing my
							back until I fell into a peaceful slumber. He would tell us, “The one thing that I can always give to you girls is my endless love.”
						</p>

						<p>
							Since my father’s passing, I find that I am still trying to digest his absence from my life. At the same time, I am so grateful for
							all of the years we shared. While I wish we could have had more time together, I know that I received everything that a daughter
							could ever wish for from her father.
						</p>

						<p>
							My father taught me about living passionately, working hard, and our obligation to bring goodness and godliness into the world. It
							was a blessing to work with him for 15 years and learn firsthand how to be an effective leader. However, my father’s greatest lesson
							to me was that no matter how important our work in the world might be, family always comes first. For my father, the most important
							title in the world (and he had many) was “<em>abba</em>.”
						</p>

						<p>
							According to Jewish tradition, a righteous person is more powerful in death than in life. In life, physical limitations and laws of
							nature restrict us. However, the soul of a righteous person is unbound and can go beyond physicality to affect the world positively.
							This is the only way I can explain that, since my father’s passing, <em>The Fellowship</em> has grown stronger and more effective
							than ever before.
						</p>

						<p>
							I know that my father is cheering me on in my role as president and CEO of <em>The Fellowship</em>, but I also know that he is
							primarily concerned about me as his daughter. I know this, because I witnessed each day as he always put family first. Everything he
							did, he did for his family, and I am so blessed to carry on his ministry. I pray that I follow his example as an effective leader,
							humble servant, and loving parent.
						</p>

						<p><a href="https://www.ifcj.org/news/yaels-holy-land-reflections/">Read more of Yael’s reflections from the Holy Land.</a></p>

						<img src="/static/images/Reflection-14550106.jpg" alt="Image with Grandkids" class="img-fluid" />
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Hero from '@/components/Hero.vue'
export default {
	name: 'MemoriesReflection',
	components: {
		Hero
	}
}
</script>

<style lang="scss" scoped>

</style>
