<template>
	<div class="bridge-builder">
		<Hero title="how to be a bridge builder" class-name="bridge-builder" />
		<main>
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-12">

						<p>
							During Rabbi Yechiel Eckstein’s years as a rabbi and teacher, he frequently reminded us to take to heart the biblical words,
							<em>“How good and pleasant it is when God’s people live together in unity!”</em> (Psalm 133:1). He believed that Christians and Jews
							could be a powerful force for good if they acted together.
						</p>
						<p>
							He dedicated his life to building bridges between Christians and Jews, bringing us together through our common values and shared
							beliefs. He believed that it was time to end 2,000 years of misunderstanding and hostility that have marked the relationship between
							Christians and Jews. He taught that when we focus on what we have in common – a shared belief in the God of Abraham, Isaac, and
							Jacob – we create a powerful bond.
						</p>
						<p>
							At <em>The Fellowship</em>, we see examples of this unity every day. So often we hear stories of <em>Fellowship</em> supporters who
							give sacrificially, doing their small part to make a big difference in the lives of Jews in need around the world. Rabbi Eckstein
							felt so grateful that <em>The Fellowship</em>’s supporters continue to be an example of how God’s children can come together and
							extend love to others in need.
						</p>

						<h3>Recognize God’s Image in Everyone</h3>

						<p>
							Rabbi Eckstein taught that to be a true bridge builder, we must recognize that every single person is someone worthy of being
							treated with dignity and compassion – as someone created in the image of God.
						</p>
						<p>
							When Rabbi Eckstein spoke of this concept, he often recalled his stay at a <em>Fellowship</em>-funded hospital in Jerusalem, Shaare
							Zedek Medical Center. “I stayed at Shaare Zedek due to a medical issue,” he said. “During that stay, I not only received
							top-of-the-line medical care – I was treated with kindness, empathy, and respect. My wonderful nurses represented a true cross
							section of the diverse society that is Israel: an ultra-Orthodox Jew, a Muslim, an Ethiopian woman (bless her, she even let me
							practice speaking Amharic, the official language of her native country).
						</p>
						<p>
							“At Shaare Zedek, I wasn’t a number. I wasn’t another cog in a medical machine. I was a human being, someone worthy of being treated
							with dignity and compassion – someone created in the image of God. At Shaare Zedek, people are not only restored to physical health,
							they are healed in spirit by being given the respect that is due to every human being.”
						</p>
						<p>
							Together as Christians and Jews, we are <em>“all sons of the Most High” </em>(Psalm 82:6), and created in His image. Because of
							this, we have a responsibility to work to change the environment we live in to one of kindness, hope, and love.
						</p>

						<h3>Find Common Ground with Everyone</h3>

						<p>
							Rabbi Eckstein knew that if we were to work together to make the world a better place, then we would have to find common ground with
							all of God’s children – even with those we may strongly disagree with.
						</p>
						<p>
							One of the most striking, public examples of the Rabbi acting on this belief came even before he founded <em>The Fellowship</em>. In
							1980, Reverend Bailey Smith, then president of the Southern Baptist Convention, made the infamous statement that “God Almighty does
							not hear the prayers of a Jew.” While Jewish groups – including the Anti-Defamation League (ADL), who Rabbi then worked for – were
							outraged, Rabbi Eckstein saw it as a teachable moment. Here, he thought, was a real opportunity to build a bridge, to create
							understanding by educating Christian leaders on Jewish faith and culture.
						</p>
						<p>
							With Rabbi’s encouragement, the ADL invited Pastor Smith and a delegation of Christian leaders to come to Israel. The Rabbi
							accompanied the group, which upon its arrival was received by then-Prime Minister Menachem Begin and given a tour of the country.
							Throughout the trip, Rabbi and members of the delegation engaged in frank and thought-provoking discussions. The Rabbi taught them
							about Judaism and sensitized them to the Jews’ ongoing struggle against anti-Semitism while he, in turn, learned firsthand about
							Christian beliefs.
						</p>
						<p>
							The trip ended up being a resounding success. In appreciation, Pastor Smith invited Rabbi Eckstein to speak at his church on the
							subject of Jewish-Christian relations. He was warmly received, and was subsequently invited to other Baptist churches as well. “From
							that time on,” the Rabbi later recalled, “I had an open door to Southern Baptist congregations across the country.” A door had been
							opened; a bridge had been built.
						</p>
						<p>
							And the bridge between Jews and evangelical Christians that the Rabbi built has been a source of untold good for Jews in need in
							Israel and around the world. This, for him, was the essence of being a bridge builder: finding common ground with others so we can
							work together to meet the needs of the most vulnerable and impoverished. It is then that we take one step closer to realizing the
							Jewish concept of <em>tikkun olam</em> – “repairing the world.”
						</p>

						<h3>Start Small with Everyone</h3>

						<p>
							Rabbi Eckstein wanted every person to feel empowered to be a bridge builder in their daily lives, and he taught that we can begin
							with simple things.
						</p>
						<p>
							We can be the ones who say “hello” and “thank you” to the waitress at the restaurant and the postman delivering mail, or to invite
							someone who is going through hard times to get a cup of coffee and check in with them. One simple act of kindness just might turn
							someone’s bad day into a good day, and give that person the will to extend kindness to others.
						</p>
						<p>
							A small act of kindness can also take the form of a small act of sacrifice. Rabbi Eckstein loved to tell the story of one of
							<em>The Fellowship</em>’s wonderful supporters who started buying a less expensive coffee every day rather than the Starbucks she
							enjoyed, so she could donate the money she saved monthly to support Israel and her people through <em>The Fellowship</em>. Through
							this simple gesture she was doing her part to reach out and comfort those in need.
						</p>
						<p>
							Rabbi Eckstein truly believed we can all be bridge builders and help to make a kinder and better world. It doesn’t matter who you
							are, what you do, or what you know; everyone has the ability to extend love to all people and respect all people, and to teach
							others to do the same.
						</p>

						<h3>A Rich Legacy for Everyone</h3>

						<p>
							For three years before he died, the Rabbi mentored and guided his daughter, Yael, in her senior leadership role at <em>The Fellowship</em>. He
							handed over to her many of the responsibilities that were once his, and made sure that she knew how to handle any situation. He gave
							her opportunities to act as a leader, and taught her by example how to lead.
						</p>
						<p>
							When the Rabbi passed, Yael Eckstein became <em>The Fellowship</em>’s president and CEO with the unanimous blessing of <em>The Fellowship</em>’s
							Board of Directors. Under her leadership, <em>The Fellowship</em> will continue to answer the biblical call to bless and care
							for the Jewish people. Her father started the work, but there are still many more bridges to build – and Yael will carry on
							this holy work, guiding us into a strong future. Just as God prepared and called Rabbi Eckstein for this great work of building
							bridges, God has prepared Rabbi’s daughter to continue the rich legacy that her father left to us all.
						</p>
						<a href="http://www.ifcj.org/">Learn more about how Yael Eckstein and <em>The Fellowship</em> are carrying on her father’s bridge building legacy.</a> <a href="https://www.ifcj.ca">Canadian residents, please visit IFCJ.ca.</a>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Hero from '@/components/Hero.vue'
export default {
	name: 'BridgeBuilder',
	components: {
		Hero
	}
}
</script>

<style lang="scss" scoped></style>
