<template>
	<div id="app">

		<Header />

		<router-view></router-view>

		<Footer />

	</div>
</template>

<script>
import Header from "./views/partials/Header.vue";
import Footer from "./views/partials/Footer.vue";

export default {
	name: "App",
	data () {
		return {

		}
	},
	components: {
		Header,
		Footer
	}
	// created() {
	// 	let addthis = document.createElement('script')
	// 	addthis.setAttribute('src', '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5e13966109d31134')
	// 	document.head.appendChild(addthis)
	// }
};
</script>

<style lang="scss">
.grecaptcha-badge {

	display: none !important;
}
</style>
