<template>
	<div>
		<button class="toggle-btn" v-on:click="openDrawer()"><span>Menu</span></button>
		<aside class="navigation-drawer d-lg-none" :class="{ active: isMenuActive }">
			<div class="drawer-container">
				<div class="drawer-close" v-on:click="closeDrawer()">
					<i class="fas fa-times"></i>
				</div>

				<ul class="drawer-links">
					<li class="drawer-link" v-on:click="closeDrawer()"><router-link to="/">Home</router-link></li>
					<li class="drawer-link dropdown">
						<router-link to="/legacy">Legacy</router-link>
						<ul class="dropdown__content">
							<li class="drawer-link" v-on:click="closeDrawer()">
								<router-link to="/legacy/biography">Biography</router-link>
							</li>
							<li class="drawer-link" v-on:click="closeDrawer()">
								<router-link to="/legacy/ministry">Ministry</router-link>
							</li>
							<li class="drawer-link" v-on:click="closeDrawer()">
								<router-link to="/legacy/quotes">Quotes</router-link>
							</li>
						</ul>
					</li>
					<li class="drawer-link" v-on:click="closeDrawer()"><router-link to="/gallery">Media Gallery</router-link></li>
					<li class="drawer-link" v-on:click="closeDrawer()"><router-link to="/memorial-coverage">Memorial Coverage</router-link></li>
					<li class="drawer-link dropdown">
						<router-link to="/memories">Memories</router-link>
						<ul class="dropdown__content">
							<li class="drawer-link" v-on:click="closeDrawer()">
								<router-link to="/memories/memories-condolences">Memories and Condolences</router-link>
							</li>
							<li class="drawer-link" v-on:click="closeDrawer()">
								<router-link to="/memories/share-your-memory">Share Your Memory</router-link>
							</li>
							<li class="drawer-link" v-on:click="closeDrawer()">
								<router-link to="/memories/yael-eckstein-reflection">Yael Eckstein’s Reflection</router-link>
							</li>
						</ul>
					</li>
					<li class="drawer-link" v-on:click="closeDrawer()"><router-link to="/library">Library</router-link></li>
					<li class="drawer-link dropdown">
						<router-link to="/more">More</router-link>
						<ul class="dropdown__content">
							<li class="drawer-link" v-on:click="closeDrawer()">
								<router-link to="/more/contact">Contact</router-link>
							</li>
							<li class="drawer-link" v-on:click="closeDrawer()">
								<router-link to="/more/bridge-builder">How to Be a Bridge Builder</router-link>
							</li>
						</ul>
					</li>
				</ul>
			</div>
			<div class="drawer-overlay"></div>
		</aside>
	</div>
</template>

<script>
export default {
	name: 'NavigationMobile',
	data() {
		return {
			isMenuActive: false
		}
	},
	methods: {
		openDrawer() {
			this.isMenuActive = true
		},

		closeDrawer() {
			this.isMenuActive = false
		}
	}
}
</script>

<style lang="scss">
$brown: #5c3900;

.toggle-btn {
	display: inline-block;
	padding: 1rem 3rem;
	cursor: pointer;
	color: white;
	border: 1px solid transparent;
	background: $brown;
	box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.15);
	outline: 0;
	width: 100%;
	text-transform: uppercase;
	transition: background-color 0.25s ease-out, color 0.25s ease-out;
	&:hover {
		opacity: 0.9;
		transition: background-color 0.25s ease-out, color 0.25s ease-out;
	}
	span {
		font-weight: bold;
		&:hover {
			opacity: 0.5;
		}
	}
}

.navigation-drawer {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	opacity: 0;
	visibility: hidden;
	transition: all 0.35s ease-in-out;

	&.active {
		opacity: 1;
		visibility: visible;

		.drawer-container {
			transform: translateX(0);
		}
	}

	.drawer-overlay {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1001;
		background-color: rgba(#000, 0.5);
	}

	.drawer-container {
		position: relative;
		overflow-y: scroll;
		z-index: 1002;
		transform: translateX(-100%);
		width: 85%;
		height: 100%;
		padding: 1.5rem;
		background-color: $brown;
		box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.15);
		transition: all 0.35s ease-in-out;

		.drawer-close {
			position: absolute;
			top: 0;
			right: 0;
			padding: 1rem 1.5rem;
			cursor: pointer;
			font-size: 1.5rem;
			color: #fff;
			opacity: 0.5;

			&:hover {
				opacity: 1;
			}
		}

		.drawer-links {
			list-style: none;
			margin: 2.5rem 0 0 0;
			padding: 0;

			.drawer-link {
				font-size: 1.25rem;

				a {
					display: block;
					width: 100%;
					padding: 1rem 0.8rem;

					&:link,
					&:visited {
						color: #fff;
						font-weight: 700;
					}

					&:hover,
					&:active,
					&:focus {
						background-color: rgba(#333, 0.5);
						text-decoration: none;
					}
				}
			}

			.dropdown {
				.dropdown__content {
					display: block;
					min-width: 200px;
					padding: 0;
					width: 100%;
					height: 0;
					overflow: hidden;
					transition: all 0.15s ease-in-out;
					background: rgba(#fff, 0.25);
				}

				&:hover .dropdown__content {
					height: 100%;
				}
			}
		}
	}
}

@media screen and (min-width: 992px) {
	.navigation-drawer,
	.toggle-btn {
		display: none;
	}
}
</style>
