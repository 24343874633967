<template>
	<div id="home">
		<section class="hero">
			<div class="hero-title">
				<h1>
					Rabbi Yechiel Eckstein
				</h1>
				<h2>
					1951 &ndash; 2019
				</h2>
			</div>
		</section>

		<div class="container">
			<div class="row justify-content-center p-5">
				<p class="hero__text">
					Rabbi Yechiel Eckstein founded the <em>International Fellowship of Christians and Jews</em> in 1983. In his more than three decades of work,
					Rabbi Eckstein not only raised billions for needy Jews around the world, but built bridges of understanding between Christians and Jews by
					fostering dialogue between the faiths and helping Christians deepen their bonds with their biblical Jewish roots.
				</p>
			</div>
		</div>

		<Ribbon primary-text="How Did God Use Rabbi Eckstein in Your Life?" secondary-text="Share Your Memory Here" url="/memories/share-your-memory" />

		<div class="share-container">

			<SocialShare theme="dark" />

		</div>

		<main>
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-12">
						<Cards v-bind:collection="cards" />
					</div>
				</div>
			</div>
		</main>
		<div class="text-center">
			<Ribbon primary-text="In Honor of Rabbi Eckstein" secondary-text="Please Consider a Memorial Gift to <em>The Fellowship</em>" url="https://help.ifcj.org/donate/85/0"/>
		</div>
	</div>
</template>

<script>
import Ribbon from '@/components/Ribbon.vue';
import SocialShare from '@/components/SocialShare.vue';
import Cards from '@/components/Cards.vue';

export default {
	name: 'Home',
	components: {
		Ribbon,
		SocialShare,
		Cards
	},
	data () {
		return {
			cards: [
				{
					title: 'Memorial Coverage',
					content: 'See photos and videos of the Rabbi Eckstein memorial events.',
					imageUrl: '/static/images/RYE-Funeral-14626131.jpg',
					linkUrl: '/memorial-coverage'
				},
				{
					title: 'Memories & Condolences',
					content: 'Leaders from around the globe pay tribute to Rabbi Eckstein.',
					imageUrl: '/static/images/RYE-Pat-Robertson-14433795.jpg',
					linkUrl: '/memories/memories-condolences'
				},
				{
					title: 'Yael Eckstein’s Reflection',
					content: 'Yael Eckstein describes the influence of her father on her life.',
					imageUrl: '/static/images/Reflection-14550106.jpg',
					linkUrl: '/memories/yael-eckstein-reflection'
				},
				{
					title: 'How to be a Bridge Builder',
					content: 'It starts with a simple belief that we are all children of God.',
					imageUrl: '/static/images/BridgeBuilder-14648477.jpg',
					linkUrl: '/more/bridge-builder'
				}
			]
		}
	}
}
</script>

<style lang="scss" scoped>
.hero {

	height: 85vh;
	min-height: 550px;
	background-image: linear-gradient(to right top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.15)), url('/public/static/images/Hero-14548438.jpg');
	background-size: cover;
	background-position: center 60%;
	position: relative;

	.hero-title {

		position: absolute;
		right: 10%;
		top: 8%;
		text-align: right;
		color: #fff;
		text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
	}
}

.share-container {

	padding-top: 2rem;
}

@media (max-width: 420px) {

	.hero {

		h1 {

			font-size: 1.7rem !important;
			left: 5%;

			span {

				font-size: 1.5rem !important;
			}
		}
	}
}

@media (max-width: 575px) {

	.hero {

		width: 100%;
		height: auto;
		max-height: 500px;
	}
}

@media (max-width: 576px) {

	.hero {

		h1 {

			font-size: 2.3rem;

			span {

				font-size: 2.1rem;
			}
		}
	}
}

@media (min-width: 768px) {

	.hero {

		h1 {

			font-size: 2.5rem;

			span {

				font-size: 2.3rem;
			}
		}
	}
}

@media (min-width: 992px) {

	.hero {

		h1 {

			font-size: 3rem;

			span {

				font-size: 2.8rem;
			}
		}
	}
}

@media (min-width: 1200px) {

	.hero {

		h1 {

			font-size: 3.5rem;

			span {

				font-size: 3.3rem;
			}
		}
	}
}
</style>
