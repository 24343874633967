<template>
	<div class="memories">
		<Hero title="Memories" class-name="memories" />
		<main>
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-12">
						<Cards v-bind:collection="cards" />
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import Cards from '@/components/Cards.vue'

export default {
	name: 'Memories',
	components: {
		Hero,
		Cards
	},
	data() {
		return {
			cards: [
				{
					title: 'Memories and Condolences',
					content: 'Prominent leaders from around the globe pay tribute to Rabbi Eckstein.',
					imageUrl: '/static/images/RYE-Pat-Boone-14555392.jpg',
					linkUrl: '/memories/memories-condolences'
				},
				{
					title: 'Share Your Memory',
					content: 'How did Rabbi Eckstein influence your life?',
					imageUrl: '/static/images/Food-Drive-14466477.jpg',
					linkUrl: '/memories/share-your-memory'
				},
				{
					title: "Yael Eckstein's Reflection",
					content:
						'Yael Eckstein describes the influence of Rabbi Eckstein on her life as a daughter and as the next generation leader of <em>The Fellowship</em>.',
					imageUrl: '/static/images/Reflection-14550106.jpg',
					linkUrl: '/memories/yael-eckstein-reflection'
				}
			]
		}
	}
}
</script>

<style lang="scss" scoped></style>
