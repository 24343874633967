<template>
	<nav>
		<ul class="nav__menu">
			<li><router-link to="/">Home</router-link></li>
			<li class="dropdown">
				<router-link to="/legacy">Legacy</router-link>
				<ul class="dropdown__content">
					<li><router-link to="/legacy/biography">Biography</router-link></li>
					<li><router-link to="/legacy/ministry">Ministry</router-link></li>
					<li><router-link to="/legacy/quotes">Quotes</router-link></li>
				</ul>
			</li>
			<li><router-link to="/gallery">Media Gallery</router-link></li>
			<li><router-link to="/memorial-coverage">Memorial Coverage</router-link></li>
			<li class="dropdown">
				<router-link to="/memories">Memories</router-link>
				<ul class="dropdown__content">
					<li><router-link to="/memories/memories-condolences">Memories and Condolences</router-link></li>
					<li><router-link to="/memories/share-your-memory">Share Your Memory</router-link></li>
					<li><router-link to="/memories/yael-eckstein-reflection">Yael Eckstein’s Reflection</router-link></li>
				</ul>
			</li>
			<li><router-link to="/library">Library</router-link></li>
			<li class="dropdown">
				<router-link to="/more">More</router-link>
				<ul class="dropdown__content">
					<li><router-link to="/more/contact">Contact</router-link></li>
					<li><router-link to="/more/bridge-builder">How to Be a Bridge Builder</router-link></li>
				</ul>
			</li>
		</ul>
	</nav>
</template>

<script>
export default {
	name: 'NavigationDesktop'
}
</script>

<style lang="scss" scoped>

$brown: #5C3900;
$white: #fff;

nav {

	position: fixed;
	z-index: 1000;
	height: 75px;
	width: 100%;
	font-size: 16px;
	background-color: $brown;
	box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.15);

	.nav__menu {

		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0;
		padding: 0;

		a {
			display: flex;
			color: #fff;
			padding: 0 1.5rem;
			height: 75px;
			align-items: center;
			justify-content: center;
			transition: all 0.15s ease-in-out;
			text-decoration: none;

			&:hover, &.active {
				color: $white;
				background-color: rgba(255, 255, 255, 0.25);
				box-sizing: border-box;
				position: relative;
			}
		}

		.dropdown {

			position: relative;

			.dropdown__content {

				position: absolute;
				visibility: hidden;
				opacity: 0;
				min-width: 200px;
				background-color: #eee;
				margin: 0;
				padding: 0;
				box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
				transition: all 0.15s ease-in-out;

				a {
					color: $brown;
					padding: 1rem 1.5rem;
					justify-content: flex-start;

					&:hover {

						background-color: #fff;
						font-weight: 700;
					}
				}


			}

			&:hover .dropdown__content {

				visibility: visible;
				opacity: 1;
			}
		}
	}
}

@media screen and (max-width: 992px) {
	nav {
		display: none;
	}
}
</style>
