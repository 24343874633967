import { render, staticRenderFns } from "./Legacy.vue?vue&type=template&id=f167a9d6&scoped=true&"
import script from "./Legacy.vue?vue&type=script&lang=js&"
export * from "./Legacy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f167a9d6",
  null
  
)

export default component.exports