<template>
	<header>
		<NavigationDesktop class="d-xs-none" />
		<NavigationMobile />
	</header>
</template>

<script>
import NavigationDesktop from "./Navigation/Desktop.vue";
import NavigationMobile from "./Navigation/Mobile.vue";

export default {
	data() {
		return {};
	},
	name: "Header",
	components: {
		NavigationDesktop,
		NavigationMobile,
	}
};
</script>

<style lang="scss" scoped>
header {
	padding-bottom: 75px;
}

@media screen and (max-width: 992px) {
	header {
		padding: 0;
	}
}
</style>
