<template>
	<div class="ribbon">
		<div class="container">
			<div class="ribbon-container">

				<a v-bind:href="url">

					<h2 v-html="primaryText"></h2>

					<h4 v-html="secondaryText"></h4>

				</a>

			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Ribbon',
	props: {
		primaryText: {
			type: String,
			required: true
		},
		secondaryText: {
			type: String
		},
		url: {
			type: String
		}
	}
}
</script>

<style lang="scss" scoped>
.ribbon {

	padding: 2rem 0;
	background-color: #a87b32;

	.ribbon-container {

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		color: white;
		padding: 2rem 0;
		border-top: 1px solid #fff;
		border-bottom: 1px solid #fff;

		a {
			color: #fff;
			text-decoration: none;
		}
	}
}
</style>
