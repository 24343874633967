<template>
	<div class="quotes">
		<Hero title="Quotes" class-name="quotes" />
		<main>
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-12">
						
						<p>Throughout his life, Rabbi Eckstein was a beloved and prolific teacher. And he was also a bold and provocative thinker. While he left us much too soon, we are thankfully left with the Rabbi’s many words of wisdom, as are future generations of bridge builders and people of faith committed to fellowship among all of God’s children.</p>

						<hr/>

						<h3>On Ministry</h3>

						<p>The problem with our time is that the committed often lack tolerance and the tolerant often lack commitment.</p>

						<p>From the very beginning this work has been a divine calling, and, looking back, I see God’s hand throughout this long journey.</p>

						<p>
							<em>The Fellowship</em> does its work because God tells us to help the poor, the downtrodden, and the oppressed, to do our best to
							repair a broken world.
						</p>

						<p>The highest form of charity in the Jewish tradition is giving someone the means to support himself.</p>

						<h3>On Israel</h3>

						<p>
							I can think of no better, more direct way to strengthen the Jewish state than by supporting those who venture into danger in order
							to protect the innocent each and every day.
						</p>
						<p>
							When you offer your support for Israel, you offer a powerful response to this rising tide of evil, and show that you stand firmly
							with a God of life and light.
						</p>
						<p>
							In Israel’s commitment to upholding the rights of women, both in law and practice, the Jewish state offers a model for the entire
							region, and stands, as it does in so many other ways, as a beacon of freedom and equality in a sea of oppression.
						</p>

						<h3>On Christians and Jews</h3>

						<p>
							There is another alliance, however, that is growing and flourishing – the alliance between the Jewish community and the millions of
							evangelical Christians who have proven themselves to be staunch supporters of Israel, who support the Jewish state and her people
							materially and in prayer.
						</p>
						<p>
							I have witnessed much healing in the once-broken relationship between Jews and Christians…we have come to embody the beautiful
							biblical truth: “<em>Two are better than one, because they have a good return for their labor: If either of them falls down, one can help the other up</em>” (Ecclesiastes 4:9-10).
						</p>
						<p>
							I have been moved by the sweeping Christian support of Israel and Jewish people worldwide, by the offering of financial support to
							bring victims of anti-Semitism home to Israel, by the outpouring of prayer for those facing hatred and violence because they are
							Jewish.
						</p>
						<p>
							For perhaps the first time in history, Jews are not alone; there are tens of millions of Christians all over the world who stand
							with us, who are extending their hand in support, compassion, and friendship.
						</p>

						<h3>On the Bible</h3>

						<p>
							It is the Bible that brings us solace, inner strength, and spiritual fulfillment during times of joy, security, and prosperity, as
							well as during periods of wandering, suffering, and adversity. It guides our path, shapes our character, and links us with the
							Almighty.
						</p>

						<p>
							It is such a blessing that Jews and Christians can turn to the Bible to remember that we are admonished to listen, both to God and
							to each other, and to exercise kindness in our words and expression.
						</p>

						<p>God’s Word is for <em>all</em> people…it binds together not only Jews, but Jews and Christians as well.</p>

						<h3>On Peace</h3>
						<p>
							In these troubled times, may we all pray for the day when God will bless us with his most precious of gifts – the gift of
							<em>shalom</em>, peace.
						</p>
						<p>
							With faith in a God Who can work miracles, we continue to believe that peace is possible, no matter how unlikely it may seem, and no
							matter how strongly our enemies may work against it.
						</p>
						<p>
							Let us pledge to continue to believe in and work to repair the world, to be forces for peace, and to relieve the suffering of
							others. Because the world needs us now more than ever.
						</p>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Hero from '@/components/Hero.vue'
export default {
	name: 'Quotes',
	components: {
		Hero
	}
}
</script>

<style lang="scss" scoped></style>
