<template>

	<div class="social-share" v-bind:class="theme">

		<div>Share The Legacy</div>

		<div class="social-icons">

			<a class="addthis_button_facebook">
				<i class="fab fa-facebook-square social-icon"></i>
			</a>

			<a class="addthis_button_twitter">
				<i class="fab fa-twitter-square social-icon"></i>
			</a>

		</div>

	</div>

</template>

<script>
export default {
	name: 'SocialShare',
	props: {
		theme: {
			type: String,
			default: 'light'
		}
	}
}
</script>

<style lang="scss" scoped>
.social-share {

	display: flex;
	align-items: center;
	justify-content: center;
	width: 350px;
	border-radius: 5px;
	margin: auto;
	padding: .5rem;
	font-weight: bold;
	font-size: 1rem;
	text-transform: uppercase;

	&.dark {

		border: 1px solid #000;
		color: #000;

		.social-icon {

			color: #000;
		}
	}

	&.light {

		border: 1px solid #fff;
		color: #fff;

		.social-icon {

			color: #fff;
		}
	}

	.social-icons {

		margin-left: 1rem;

		.social-icon {

			cursor: pointer;
			margin-left: .25rem;
			opacity: .85;
			font-size: 2rem;
			transition: all 0.1s ease-in-out;

			&:hover {

				opacity: 1;
			}
		}
	}
}
</style>
