<template>
	<div class="more">
		<Hero title="More" class-name="more" />
		<main>
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-12">
						<Cards v-bind:collection="cards" />
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import Cards from '@/components/Cards.vue'

export default {
	name: 'More',
	components: {
		Hero,
		Cards
	},
	data() {
		return {
			cards: [
				{
					title: 'Contact',
					content: 'Contact IFCJ regarding the life and ministry of Rabbi Yechiel Eckstein.',
					imageUrl: '/static/images/MemorialCoverage-14091131.jpg',
					linkUrl: '/more/contact'
				},
				{
					title: 'How To Be A Bridge Builder',
					content: 'The bridge between Christians and Jews took decades, but the Rabbi’s work now spans the world and leads to Jerusalem.',
					imageUrl: '/static/images/BridgeBuilder-14648477.jpg',
					linkUrl: '/more/bridge-builder'
				}
			]
		}
	}
}
</script>

<style lang="scss" scoped></style>
