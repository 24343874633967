<template>
	<div>

		<Hero title="Memories and Condolences" class-name="condolences" />

		<main>
			<div class="container">
				<div class="row">
					<div class="col-12">
						<p>
							After Rabbi Eckstein’s passing, memories and tributes poured in from around the globe from prominent leaders whose lives were
							touched by him. It was overwhelming to hear so many kind words about his exemplary character and the good work he spent his life
							doing with <em>The Fellowship</em>. Rabbi Eckstein would have been deeply touched to hear how many friends remember him and are
							committed to carrying on his legacy. We now share their words with you.
						</p>

						<div class="row">

							<div class="col-md-4">

								<img style="margin: 1rem 1rem 1rem 0;" class="img-thumbnail" src="/static/images/RYE-Netanyahu-14430173.jpg" alt="Yechiel And Prime Minister Netanyahu Meet With Druze Scholarship" />
								
								<blockquote>
							
									<p>It is rare when one person embodies such a tremendous ability to give, as in Yechiel’s case. His unique personality represented a perfect combination of vision and fulfillment.</p>

									<footer><strong>Benjamin Netanyahu</strong>, Israeli Prime Minister</footer>

								</blockquote>								
							
							</div>

							<div class="col-md-4">

								<img style="margin: 1rem 1rem 1rem 0;" class="img-thumbnail" src="/static/images/RYE-Pat-Robertson-14433795.jpg" alt="RYE with Pat Robertson on 700 Club" />

								<blockquote>

									<p>Words cannot express the sorrow I feel at the untimely passing of my dear friend, Yechiel Eckstein. Yechiel has been a pioneer and champion of Christian/Jewish relations for decades. We have worked together on many projects, and the success of his organization attests to the compassion that he feels for his fellow Jews who suffer in poverty in various parts of the world. I am sure I echo the words of our Lord, ‘Well done, my good and faithful servant. Enter into the joy of your Lord.’</p>
									
									<footer><strong>Pat Robertson</strong>, 700 Club Founder</footer>

								</blockquote>

							</div>

							<div class="col-md-4">

								<img style="margin: 1rem 1rem 1rem 0;" class="img-thumbnail" src="/static/images/RYE-Pat-Boone-14555392.jpg" alt="Rye Yechiel Eckstein and Pat Boone" />

								<blockquote>

									<p>I will always consider my friendship and cooperative work with Yechiel Eckstein one of the most important facts of my life on
									this earth — and I hope to rejoice with him in Heaven, the ultimate Promised Land.</p>

									<footer><strong>Pat Boone</strong>, American singer and actor</footer>

								</blockquote>								

							</div>

						</div>

						<blockquote>

							<p>A great man, a great Jew, and a great Zionist. Rabbi Yechiel Eckstein, a man of charity and vision, left us today. His contribution to the Jewish people and the state of Israel will be felt for generations. We mourn his loss. May his memory be a blessing.</p>

							<footer><strong>Reuven Rivlin</strong>, Israeli President </footer>

						</blockquote>

						<blockquote>

							<p>I join millions of Jews and Christians in mourning the death of Rabbi Yechiel Eckstein. His contributions to Israel, the Jewish people, and interfaith relations were immense and his love of Zion immeasurable. I was proud to call him colleague and friend. May his memory be blessed.</p>

							<footer><strong>Michael Oren</strong>, Former Israeli Ambassador to the U.S. </footer>

						</blockquote>

						<blockquote>

							<p>It is indisputable that the warmth felt towards Jews and Israel by millions of gentle Christians – for the first time in two millennia – owes much to Yechiel Eckstein.</p>

							<footer><strong>Rabbi Daniel Lapin</strong>, President of the American Alliance of Jews and Christians</footer>

						</blockquote>

						<blockquote>

							<p>It was an honour to meet and spend time with The Fellowship founder Rabbi Yechiel Eckstein during the Together in Fellowship Gala in 2018. His lifetime dedication to peace and bridge building leaves an incredible legacy around the world. My prayers are with his family and loved ones as they mourn his untimely passing.</p>

							<footer><strong>Stephen Harper</strong>, Former Prime Minister of Canada</footer>

						</blockquote>

						<blockquote>

							<p>I was deeply saddened and shocked to learn of the sudden passing of our beloved leader and Rabbi, Yechiel. I extend to his family my deepest sympathies. I am proud that Canadians have supported Yechiel's efforts to bring assistance and comfort to those in need, to assist Jews at risk to make aliyah (immigration to Israel), and to build bridges of friendship, understanding, and solidarity between Christians and Jews. We pledge ourselves to continue Yechiel's legacy. May his memory be a blessing.</p>

							<footer><strong>David Berger</strong>, Chair of IFCJ Canada and Former Canadian Ambassador to Israel</footer>

						</blockquote>
						
						<blockquote>

							<p>I cannot think of anyone I have ever known who did more good for more people than Yechiel Eckstein. The Jewish people and the
							Jewish state of Israel have never had a better friend and supporter than Yechiel Eckstein. He was, like Moses our teacher, a man
							of God.</p>

							<footer><strong>Joe Lieberman</strong>, Former U.S. Senator</footer>

						</blockquote>

						<blockquote>

							<p>Yechiel believed that bringing non-Jews into the miraculous rebirth of the state of Israel was part of our divine mandate as a
							nation and his personal calling. Those of us who are trying to follow in his footsteps by working to strengthen Christian support of Israel realize that none of
							us would be doing what we do but for him.</p>

							<footer><strong>Rabbi Tuly Weisz</strong>, Writing at the Jerusalem Post</footer>

						</blockquote>

						<blockquote>

							<p>He was one of the most significant figures in recent American Jewish history, as well as a man responsible for a vast tide of
							tzedekah that sustained countless Jews in need. May his memory be a blessing.</p>

							<footer><strong>Jonathan Tobin</strong>, JNS Editor-in-Chief</footer>

						</blockquote>

						<blockquote>

							<p>His impact on the state of Israel and on bringing Jews and Christians together will be felt for generations.</p>

							<footer><strong>John Hagee</strong>, Founder of Christians United for Israel</footer>

						</blockquote>

						<blockquote>

							<p>Yechiel Eckstein demonstrated, to us and to the world, that gentile support for Israel need not be political; need not be
							theological; need not be rooted in anything other than a love for the Jewish people.</p>

							<footer><strong>Rabbi Jeffrey Salkin</strong>, Senor Rabbi of Temple Solel in Florida</footer>

						</blockquote>

					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Hero from '@/components/Hero.vue'
export default {
	name: 'MemoriesCondolences',
	components: {
		Hero
	}
}
</script>

<style lang="scss" scoped>
.quotes {

	list-style: none;
	padding: 0;
	margin: 0;
}

.gallery {
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	grid-template-rows: repeat(8, 5vw);
	grid-gap: 1.5rem;

	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		display: block;
		box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);

		&:hover {
			// transform: scale(1.05) translateY(-0.5rem);
			box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.5);
			cursor: pointer;
		}
	}
	&__item {
		&--1 {
			grid-column: 1 / span 8;
			grid-row: 1 / span 3;
		}
		&--2 {
			grid-column: 1 / span 4;
			grid-row: 4 / span 8;
		}
		&--3 {
			grid-column: 5 / -1;
			grid-row: 4 / span 8;
		}
	}
}
</style>
