const Cookies = {

	setCookie(name, value, expiration, domain) {

		let d = new Date();

		d.setTime(d.getTime() + (expiration * 24 * 60 * 60 * 1000));

		document.cookie = name + '=' + value + ';expires=' + d.toUTCString() + ';domain=' + domain + ';path=/';
	},

	getCookie (name) {

		let cookies = document.cookie.split('; ');

		for (let i = 0; i < cookies.length; i++) {

			let c = cookies[i].split('=');

			if (c[0] === name) {

				return c[1];
			}
		}

		return null;
	}
};

export default Cookies;
