<template>
	<div class="biography">
		<Hero title="Biography" class-name="biography" />
		<main>
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-12">
						<p>
							Rabbi Yechiel Eckstein founded the <em>International Fellowship of Christians and Jews</em> (<em>The Fellowship</em>) in 1983 and
							devoted his life to building bridges of understanding and cooperation between Christians and Jews and broad support for the state of
							Israel. But aside from being the man who built <em>The Fellowship</em> into one of the top 400 nonprofits in America, he was much,
							much more.
						</p>

						<p>
							Born in Massachusetts to Rabbi Sy and Belle Eckstein, Yechiel Eckstein was raised in Ottawa, Canada, where his father was the rabbi
							of the Canadian capital. Following in his father’s footsteps, he received Orthodox Rabbinic ordination from Yeshiva University in
							New York, and earned degrees from Yeshiva University and Columbia University. He put this education to good use, serving on the
							faculties of Columbia University, Chicago Theological Seminary, and Northern Baptist Seminary.
						</p>

						<p>
							After rabbinical school, Rabbi Eckstein worked for the Anti-Defamation League (ADL) in New York promoting interfaith activism. The
							ADL sent him to Chicago in the 1970s, at the time that the American Nazi Party was planning to march through Skokie, a suburb with a
							large Jewish community, many of them Holocaust survivors.
						</p>

						<p>
							Here, Rabbi Eckstein saw great solidarity between Christians and Jews. He found strong allies for the Jewish people in the Christian
							community and committed himself to teaching Christians about Judaism, organizing dialogue between the two faiths, and stretching a
							hand of friendship across a divide that had existed for millennia.
						</p>

						<p>
							He eventually settled in Chicago, and raised three daughters — Talia, Tamar, and Yael, who took up his mantle as
							<em>The Fellowship</em>’s leader after her father’s untimely passing — and throughout his life cherished and took great joy in his
							children and the eight grandchildren they would provide him.
						</p>										

						<img class="img-thumbnail mb-3" src="/static/images/Bio-Portrait-14307625.jpg" alt="Yael Eckstein Biography"/>

						<p>
							Through the work of <em>The Fellowship</em>, the interfaith connections Rabbi Eckstein first encountered in Chicago began to grow
							and flourish. He became one of the leading international Bible teachers, helping Christians deepen their bonds with Israel and their
							biblical Jewish roots. He reached millions of people through radio programs that aired on thousands of radio outlets around the
							world.
						</p>

						<p>
							As one of America’s preeminent rabbis, Eckstein spoke at the National Day of Prayer, opened sessions of the U.S. Senate with prayer,
							and conducted the first Passover <em>seder</em> for U.S. senators. He was also a leading religious figure worldwide. He traveled to
							China to work for the freedom of imprisoned Christian pastors and to champion the cause of religious liberty. He brought the first
							<em>Torah</em> scroll to Uzbekistan since the Communist regime banned religious practice there in 1917. He served as an adviser to
							Israeli Prime Minister Ariel Sharon, and, in 2005, was appointed official Goodwill Ambassador of the state of Israel. His
							philanthropic work outside of <em>The Fellowship</em> also included serving on the executive board of the American Jewish Join
							Distribution Committee (JDC) and his own charitable giving to, among other organizations, the Friends of the Israel Defense Forces
							(FIDF).
						</p>

						<p>
							His work and words appeared in print as well, in such publications as
							<em>The New York Times, The Wall Street Journal, Time, U.S. News and World Report, The Jerusalem Post, People magazine</em>, and
							others. He authored highly acclaimed books and was also a renowned Israeli Hasidic singer who released several CDs. Yechiel shared
							his musical talents in the Holy Land when he was there as a young man. During the 1973 <em>Yom Kippur</em> War, he performed for IDF
							troops on the front lines and in hospitals.
						</p>
						<p>
							And it was this love for Israel, the biblical and historic homeland of his people, that led Rabbi Eckstein to make
							<em>aliyah</em> (immigrate to Israel) on March 19, 2001. This not only allowed him to direct <em>The Fellowship’s</em> philanthropic programs
							on the ground, but fulfilled his dream of calling the Holy Land home. And it was in his beloved Holy Land where he passed away, at his modest, three-bedroom home in Jerusalem, on February 6, 2019.
						</p>
						<p>
							But Rabbi Eckstein is not forgotten. He is survived by his wife Joelle, his three daughters, and his eight grandchildren, and is
							remembered by the millions of people around the world whose lives he helped change for the better.
						</p>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Hero from '@/components/Hero.vue'
export default {
	name: 'Biography',
	components: {
		Hero
	}
}
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 768px) {
	.biography img {
		float: right;
		max-width: 50%;
		margin: 0 1rem 1rem 0;
	}
}
</style>
