<template>
	<section class="hero" v-bind:class="[className]">
		<div class="hero-overlay">
			<h1 class="hero-title">
				{{title}}
			</h1>
			<h2>
				Rabbi Yechiel Eckstein<br />
				1951 &ndash; 2019<br />
				{{subTitle}}
			</h2>
		</div>
	</section>
</template>

<script>
export default {
	name: 'Hero',
	props: {
		className: String,
		title: String,
		subTitle: String
	}
}
</script>

<style lang="scss" scoped>
.hero {
	height: 35rem;
	background-size: cover;

	.hero-overlay {
		height: inherit;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		color: #fff;
		user-select: none;

		h1 {

			text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
		}

		h2 {
			position: absolute;
			top: 15%;
			opacity: .75;
		}
	}
}

.biography {
	background-image: url('/public/static/images/Bio-14625641.jpg');
	background-position: center 13%;
}
.legacy,
.ministry {
	background-image: url('/public/static/images/Ministry-14483438.jpg');
	background-position: center center;
}
.quotes {
	background-image: url('/public/static/images/Biography-14310091.jpg');
	background-position: center 12%;
}
.media-gallery {
	background-image: url('/public/static/images/RYE-Filming-Israel-14257689.jpg');
	background-position: center 35%;
}
.memorial-coverage {
	background-image: url('/public/static/images/RYE-Funeral-14626131.jpg');
	background-position: center 35%;
}
.memories,
.reflection {
	background-image: url('/public/static/images/Reflection-14550100.jpg');
	background-position: center 35%;
}
.condolences {
	background-image: url('/public/static/images/RYE-and-Pat-Boone-Hero-14555392.jpg');
	background-position: center 25%;
}
.share-your-memory {
	background-image: url('/public/static/images/Nizin-Family-Freedom-14404267.jpg');
	background-position: center 35%;
}
.library {
	background-image: url('/public/static/images/Library-14308065.jpg');
	background-position: center 35%;
}
.more,
.bridge-builder {
	background-image: url('/public/static/images/BridgeBuilder-14648477.jpg');
	background-position: center 35%;
}
.contact {
	background-image: url('/public/static/images/MemorialCoverage-14091131.jpg');
	background-position: center 35%;
}

@media (max-width: 576px) {
	.hero-overlay {
		h1 {
			font-size: 2.3rem;
			span {
				font-size: 2.1rem;
			}
		}
	}
}

@media (min-width: 768px) {
	.hero-overlay {
		h1 {
			font-size: 2.5rem;
			span {
				font-size: 2.3rem;
			}
		}
	}
}

@media (min-width: 992px) {
	.hero-overlay {
		h1 {
			font-size: 3rem;
			span {
				font-size: 2.8rem;
			}
		}
	}
}

@media (min-width: 1200px) {
	.hero-overlay {
		h1 {
			font-size: 3.5rem;
			span {
				font-size: 3.3rem;
			}
		}
	}
}
</style>
