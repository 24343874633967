<template>
	<footer>
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-12 text-center">

					<SocialShare class="mb-4" />

					<ul class="navigation">
						<li><router-link to="/more/contact">Contact</router-link></li>
						<li><router-link to="/memories/share-your-memory">Share Your Memory</router-link></li>
						<li><a href="https://www.ifcj.org/privacy-policy">Privacy Policy</a></li>
						<li>Visit: <a href="https://www.ifcj.org">ifcj.org</a> | <a href="https://www.ifcj.ca">ifcj.ca</a></li>
					</ul>

					<small>
						© {{ new Date().getFullYear() }} International Fellowship of Christians and Jews<sup>&reg;</sup><br />
						A 501(c)(3) tax-exempt, non-profit organization. (36-3256096)
					</small>

				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import SocialShare from '@/components/SocialShare.vue'
export default {
	name: 'Footer',
	components: {
		SocialShare
	}
}
</script>

<style lang="scss" scoped>
footer {

	background-color: #a87b32;
	padding: 2rem 0;
	font-size: 0.75rem;
	color: #fff;

	ul.navigation {

		list-style: none;
		padding: 0;
		margin: 0;
		margin-bottom: 1rem;

		li {

			display: inline-block;
			margin-right: 1rem;
			color: #fff;
			font-weight: 700;

			&:last-of-type {

				margin-right: 0;
			}
		}
	}

	a {

		&:link,
		&:visited {
			color: #fff;
			text-decoration: none;
		}

		&:hover {
			color: #f0faa1;
		}
	}
}
</style>
