import Cookies from '../Cookies.js';

const MotivationCodes = {

	setCode (motivationCode) {

		Cookies.setCookie('motivationCode', motivationCode, 90, window.location.hostname);
	},

	getCode () {

		const codes = {
			default: 'EGM0000XXEXFM',
			google: 'ESG0000XXEXXX',
			bing: 'ESB0000XXEXXX',
			yahoo: 'ESN0000XXEXXX'
		};

		if (document.referrer.indexOf('google.com') !== -1) {

			return codes.google;
		}
		else if (document.referrer.indexOf('bing.com') !== -1) {

			return codes.bing;
		}
		else if (document.referrer.indexOf('yahoo.com') !== -1) {

			return codes.yahoo;
		}
		else if (Cookies.getCookie('motivationCode') !== null && Cookies.getCookie('motivationCode') !== '') {

			return Cookies.getCookie('motivationCode');
		}
		else {

			return codes.default;
		}
	},

	isValidCode (motivationCode) {

		let expression = new RegExp(/^[a-z][a-z0-9]{2}[0-2]\d[0-1]\d[a-z0-9]{6}$/i);

		return expression.test(motivationCode);
	}
};

export default MotivationCodes;
